const userAgent = typeof window !== 'undefined' ? navigator.userAgent : null;

export const IS_PROD = process.env.NODE_ENV === 'production';
export const IS_LIGHTHOUSE = userAgent?.toLowerCase().includes('lighthouse');
export const IS_BOT = (() => {
  if (!userAgent) return false;

  return /Googlebot|Mediapartners-Google|AdsBot-Google|googleweblight|Storebot-Google|Google-PageRenderer|Google-InspectionTool|Bingbot|BingPreview|Slurp|DuckDuckBot|baiduspider|yandex|sogou|LinkedInBot|bitlybot|tumblr|vkShare|quora link preview|facebookexternalhit|facebookcatalog|Twitterbot|applebot|redditbot|Slackbot|Discordbot|WhatsApp|SkypeUriPreview|ia_archiver/i.test(
    userAgent,
  );
})();
