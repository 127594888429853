'use client';
import { useState } from 'react';

import { getCookie, setCookie } from 'cookies-next';
import { useTranslations } from 'next-intl';

import { YEARS_OLD_18_CONFIRMED, YEARS_OLD_18_DENIED_LINK } from '@/src/constants';

import Button from '../Button';
import Modal from '../Modal';
import { Typography } from '../Typography';

import styles from './YearsOld18ModalContent.module.scss';

function YearsOld18ModalContent() {
  const t = useTranslations('modal.content.18_ears_old');

  const isModalDefaultOpened = !getCookie(YEARS_OLD_18_CONFIRMED) as boolean;
  const [isOpened, setIsOpened] = useState(isModalDefaultOpened);

  const closeModal = () => setIsOpened(false);

  const onConfirm = () => {
    setCookie(YEARS_OLD_18_CONFIRMED, true);
    closeModal();
  };

  return (
    <Modal isOpen={isOpened}>
      <div className={styles.inner}>
        <div className={styles.title}>
          <h2>{t('title1')}</h2>
          <h2>{t('title2')}</h2>
          <h2>{t('title3')}</h2>
        </div>

        <Typography.Paragraph level={1} className={styles.subtitle}>
          {t('subtitle')}
        </Typography.Paragraph>

        <Button text={t('confirm_button')} className={styles.button__confirm} onClick={onConfirm} />
        <a href={YEARS_OLD_18_DENIED_LINK} className={styles.button__denied}>
          {t('denied_buttom')}
        </a>
      </div>
    </Modal>
  );
}

export default YearsOld18ModalContent;
