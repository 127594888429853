import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/analytics/google/InitAnalytics/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/ToastProvider/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/YearsOld18ModalContent/YearsOld18ModalContent.tsx");
