'use client';
import { useEffect } from 'react';

import cn from 'classnames';

import styles from './Modal.module.scss';

function Modal({ children, isOpen }: { children: JSX.Element; isOpen: boolean }) {
  useEffect(() => {
    isOpen ? (document.body.className = 'overflow') : (document.body.className = '');
  }, [isOpen]);

  return (
    <div className={cn(styles.modal, { [styles.modal__open]: isOpen })}>
      <div className={cn(styles.modalOverlay, { [styles.modalOverlay__open]: isOpen })} />
      <div className={styles.modal__content}>{children}</div>
    </div>
  );
}

export default Modal;
