import { IS_PROD } from '@/src/constants/environment';

export const analyticsApi = {
  pageview: (url: string) => {
    if (!IS_PROD) return;

    window.dataLayer.push({
      event: 'pageview',
      page: url,
    });
  },
};
