/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import { IS_LIGHTHOUSE, IS_PROD } from '@/src/constants/environment';

import { initGtagScript } from '../scripts';
const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;

export const useGtag = ({ gtagId = GTM_ID } = {}) => {
  const [loadedGtag, setStateLoaded] = useState(false);

  const loadGtag = () => {
    if (loadedGtag) return;

    initGtagScript(gtagId);
    setStateLoaded(true);
  };

  useEffect(() => {
    if (IS_LIGHTHOUSE || !IS_PROD) return;

    loadGtag();
  }, []);

  return { gtagId, isInitGtag: loadedGtag };
};
